.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1c050f07;
    backdrop-filter: blur(3px);
    padding: 5vh 0 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
}

.dialog {
    flex: 0 0 100%;
    max-width: min(calc(100vw - 32px), 420px);
    background: var(--background-primary);
    padding: 8px 16px 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 16px 8px 16px;
}

.dialog-header button {
    padding: 0;
    background: rgba(255, 255, 255, 0.05);
    color: #fff;
    width: 32px;
    height: 32px;
    font-size: 14px;
}

.dialog-header .title {
    color: var(--color-white);
    font-size: 18px;
    font-weight: 700;
}

.auth-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.auth-dialog .submit-button {
    width: 100%;
    height: 60px;
    border-radius: 12px;
    margin-top: 24px;
}
