.loader-container {
    position: absolute;
    width: auto;
    height: auto;
    z-index: 9999;
    background: var(--background-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}