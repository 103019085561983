.device-navbar {
    position: sticky;
    bottom: 0;
    left: 16px;
    right: 16px;
    background-color: var(--background-secondary);
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    gap: 24px;
    box-shadow: 0px 0px 46px -14px rgba(0, 0, 0, 1);
}

.device-navbar a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    text-decoration: none;
    color: var(--color-white);
    font-size: 14px;
    font-weight: 600;
}

.device-navbar a[data-is-active="1"]{
    color: var(--color-red) !important;
}

.device-navbar a[data-is-active="1"] span {
    color: var(--color-red) !important;
}