@keyframes bounce {
    from {
        transform: translateY(-5%);
    }

    50% {
        transform: translateY(0);
    }

    to {
        transform: translateY(5%);
    }
}

.login-component {
    min-height: 500px;
    background-color: var(--background-primary);
    border-radius: 16px;
    display: flex;
    width: 100%;
    max-width: 991px;
    flex-direction: column;
    margin-inline: auto;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.logo-big {
    animation: bounce 2s steps(1) infinite reverse;
}

.login-text {
    font-size: 32px;
    font-weight: 700;
    max-width: 577px;
}

.login-component .button-wrap {
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
}

.login-component .login-button,
.login-component .register-button {
    font-size: 20px;
    padding: 12px 24px;
}


@media (max-width: 767px) {
    .login-component {
        min-height: 300px;
        gap: 24px;
    }

    .login-text {
        font-size: 21px;
    }
}
