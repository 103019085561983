:root {
    --color-primary: #c9cba3;
    --color-secondary: #ffe1a8;
    --color-gray: #723d46;
    --color-red: #FF1B20;
    --color-black: #1c050f;
    --color-white: #fff;
    --background-primary: #000;
    --background-secondary: #0D0C0C;
}

body {
    margin: 0;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--background-secondary);
}

body * {
    font-family: 'Roboto', sans-serif;
}

* {
    box-sizing: border-box;
}

img{
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.vertical-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontal-center {
    display: flex;
    align-items: center;
}

.gap-8 {
    gap: 8px;
}

.gap-16 {
    gap: 16px;
}

.claimr-container {
    padding-top: 20px;
    width: 100%;
}

.page-container {
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 100vh;
}

main {
    width: 100%;
    max-width: 1170px;
    margin-inline: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 16px;
    position: relative;
}

.banner-placeholder {
    width: 100%;
    height: 220px;
    border-radius: 12px;
    font-size: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.2);
    background-color: rgba(0, 0, 0, 0.15);
    margin-bottom: 8px;
}

footer {
    width: 100%;
    background: var(--background-primary);
    min-height: 24px;
    color: #fff3;
    font-weight: 700;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 8px;
    box-sizing: border-box;
}

button {
    padding: 12px 24px;
    cursor: pointer;
    border: none;
    outline: none;
    color: #000;
    border-radius: 2px;
    font-weight: 700;
    transition: 0.2s;
    background: #fff;
}

button:hover {
    opacity: .8;
    scale: 1.05;
}

button:disabled {
    color: #fff8;
    background: #fff2;
}

.primary-button {
    background: var(--color-red);
    color: #fff;
}

.accent-button {
    background: var(--color-gray);
}

.banner {
    height: 320px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: .2s;
    border-radius: 8px;
    display: flex;
    padding: 32px;
    align-items: flex-start;
    cursor: pointer;
    transition: .2s;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;
    background: #ff00000c;
}

.banner-lg {
    flex: 0 0 100%;
    max-width: 100%;
}

.banner-sm {
    flex: 0 0 50%;
    max-width: calc(50% - 8px);
}

.banner button {
    transition: .2s;
    z-index: 2;
}

.banner .banner-text {
    z-index: 2;
    font-size: 24px;
    font-weight: 700;
    color: #fff;

}

.banner:hover button {
    scale: 1.1;
}

.fog {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.fog::before {
    position: absolute;
    width: 50%;
    content: '';
    height: auto;
    aspect-ratio: 3/1;
    background: ;
    z-index: -1;
    background: rgba(255, 37, 0, 1);
    filter: blur(100px);
    opacity: .5;
    transition: .6s;
}

.fog:hover::before {
    scale: 1.1;
    opacity: .7;
}

.fog-top::before {
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
}

.fog-top-right::before {
    left: 100%;
    top: 0;
    transform: translate(-50%, -50%);
}

.fog-bottom::before {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%);
}

.fog-right::before {
    top: 50%;
    left: 100%;
    transform: translate(-50%, -25%);
}

.fog-left::before {
    top: 50%;
    left: 0;
    transform: translate(-50%, -25%);
}

*:has(>.glow) {
    position: relative;
    overflow: hidden;
}

.glow {
    position: absolute;
    top: -50%;
    bottom: -50%;
    rotate: 35deg;
    left: -100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 51%, rgba(255, 255, 255, 0) 100%);
    width: 50%;
    z-index: -1;
    opacity: .0;
}

*:has(>.glow):hover .glow {
    left: 120%;
    transition: 1s;
    opacity: .4;
}