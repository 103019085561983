.face {
    display: grid;
    grid-template-areas:
        'a . c'
        'e g f'
        'd . b';
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(3, 1fr);

    flex: 0 0 auto;
    padding: 10px;
    width: 94px;
    height: 94px;

    background-color: #e7e7e7;
    box-shadow: inset 0 5px white, inset 0 -5px #bbb, inset 5px 0 #d7d7d7, inset -5px 0 #d7d7d7;
    border-radius: 10%;

    filter: drop-shadow(#0008 5px 5px 5px)
}

.pip {
    display: block;
    align-self: center;
    justify-self: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #333;
    box-shadow: inset 0 3px #111, inset 0 -3px #555;
}

.pip:nth-child(2) {
    grid-area: b;
}
.pip:nth-child(3) {
    grid-area: c;
}
.pip:nth-child(4) {
    grid-area: d;
}
.pip:nth-child(5) {
    grid-area: e;
}
.pip:nth-child(6) {
    grid-area: f;
}

.pip:nth-child(odd):last-child {
    grid-area: g;
}
