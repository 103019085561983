.dices-points {
    width: 100%;
    color: white;
    font-weight: 800;
    font-size: 28px;
    margin: 10px 0px;
    text-align: center;
    display: flex;
    gap: 10px;
    align-items: center;
}

.dices-points:before,
.dices-points:after {
    position: static;
    content: '';
    flex: 1 1 auto;
    height: 2px;
    background: #fff;
}