menu {
    display: flex;
    gap: 8px;
    margin: 0 -16px;
    position: relative;
    overflow: hidden;
    height: 47px;
}

menu a {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-white);
    opacity: .5;
    transition: .2s;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    padding: 0 8px;
}

menu span {
    transition: .2s;
    color: var(--color-white) !important;
}

menu a[data-is-active="1"] {
    opacity: .8;
}

menu a::before {
    position: absolute;
    top: -16px;
    bottom: -16px;
    left: 0;
    right: 0;
    background: var(--color-red);
    content: '';
    z-index: -1;
    filter: blur(32px);
    opacity: 0 !important;
    transition: .2s;

}

menu a[data-is-active="1"] span:first-child {
    color: var(--color-red) !important;
}

menu a[data-is-active="1"]::before {
    opacity: .2 !important;
}

menu a:hover {
    opacity: 1;
    color: var(--color-white);
}

menu a:hover span {
    color: var(--color-white) !important;
}