.games-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.game-category {
    padding: 16px;
    margin-bottom: 32px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    width: 100%;
    display: flex;
    /* justify-content: center; */
    gap: 8px;
}

.game-card {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    height: 180px;
    flex: 0 0 16.6%;
    max-width: calc(16.6% - 6px);
    background: rgba(255, 255, 255, .05);
    z-index: 1;
    cursor: pointer;
    min-width: 180px;
}

.game-card::before {
    position: absolute;
    height: 80px;
    left: 0;
    right: 0;
    content: '';
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
    transition: 0.2s;
}

.game-card img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.2s;
}

.game-name {
    font-size: 18px;
    font-weight: 700;
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    text-align: center;
    transition: 0.2s;
}

.game-card button {
    top: 110%;
    opacity: 0;
    transition: 0.2s;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.game-card button:hover {
    opacity: 0.48;
}

.game-card:hover button {
    top: calc(100% - 64px);
    opacity: 1;
}

.game-card:hover::before {
    height: 220px;
    opacity: 0.85;
}

.game-card:hover img {
    scale: 1.1;
}

.game-card:hover .game-name {
    bottom: 80px;
    font-size: 24px;
}

.game-card-empty {
    background: rgba(255, 255, 255, 0.05);
}


.game-mini-card {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    flex: 0 0 16.6%;
    padding: 16px;
    max-width: calc(16.6% - 6px);
    background: rgba(255, 255, 255, .05);
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 180px;
    justify-content: space-between;
    gap: 8px;
}

.game-mini-card:hover button span {
    transform: translateX(4px);
}

.game-mini-card:hover img {
    scale: 1.2;
}

.game-mini-card button {
    flex: 0 0 32px;
    height: 32px;
    max-width: 32px;
    padding: 0;
    border-radius: 50%;
}

.game-mini-card img {
    flex: 0 0 32px;
    max-width: 32px;
    height: 32px;
    border-radius: 6px;
    transition: .2s;
}

.game-mini-card .game-name {
    position: static;
    flex: 1 1 auto;
}