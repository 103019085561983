.game {
    width: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/public/assets/table.png');
    border: gold 2px solid;
    border-radius: 12px;
}

.game-title {
    color: white;
    font-weight: 800;
    font-size: 18px;
    margin: 10px 0px;
    text-transform: uppercase;
    text-decoration: underline;
}

.game-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
}

.bet-text {
    color: gold;
    font-weight: 800;
    font-size: 32px;
    margin: 5px 0px;
}

.bet-item,
.bet-item-selected {
    font-weight: 800;
    font-size: 20px;
    color: gold;
    background: transparent;
    border: gold 2px solid;
}

.bet-item-selected {
    color: red;
    background: #fff;
    border: #fff 2px solid;
}

.bet-button {
    padding: 16px 48px;
    font-size: 18px;
    font-weight: 600;
    color: red;
}

.username {
    margin: 4px 0px;
    font-size: 18px;
    font-weight: 600;
}


.wheel-container {
    position: relative;
}

.wheel-container .wheel-earn-spins-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #0d0c0c20;
    border-radius: 50%;
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    font-size: 16px;
    font-weight: 700;
}

.wheel-container>button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spin-game .counter {
    position: relative;
    background-color: var(--background-secondary);
    padding: 6px 12px;
    border-radius: 4px;
}

.spin-game .user-info {
    display: relative;
    background-color: var(--background-secondary);
    padding: 6px 12px;
    border-radius: 4px;
    font-weight: 600;
}

.game-info-container {
    display: flex;
    gap: 8px;
    justify-content: center;
    width: 100%;
    font-weight: 600;
    margin-bottom: 16px;
}

.game-info-container span {
    opacity: .5;
}