.input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.input-container input {
    width: 100%;
    border: none;
    height: 36px;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    color: var(--color-black);
    padding: 0 16px;
    font-weight: 600;
}

.input-container label {
    font-size: 16px;
    color: var(--color-white);
    font-weight: 700;
}