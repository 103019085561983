.wheel-dialog .dialog {
    max-width: min(calc(100vw - 32px), 991px);
}

.wheel-dialog h2 {
    /* -webkit-text-fill-color: transparent; */
    /* background: linear-gradient(0deg, #fff, red); */
    /* -webkit-background-clip: text; */
    /* background-clip: text; */
    font-size: 46px;
    font-weight: 700;
    columns: #fff;
    margin: 0 0 16px;
    text-align: center;
}

.wheel-dialog h2 span {
    color: var(--color-secondary)
}

.wheel-dialog p {
    max-width: 577px;
    opacity: .5;
    text-align: center;
    margin-inline: auto;
}