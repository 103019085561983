@keyframes bounce {
    from {
        transform: translateY(-5%);
    }

    50% {
        transform: translateY(0);
    }

    to {
        transform: translateY(5%);
    }
}

header {
    display: flex;
    padding: 6px 18px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 24px;
    background-color: var(--background-primary);
    gap: 8px;
    align-items: center;
}

.logo {
    display: flex;
    font-size: 18px;
    align-items: center;
    gap: 4px;
    max-width: 120px;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    animation: bounce 2s steps(1) infinite reverse;
}

.logo img{
    width: 100%;
}

.user-info {
    display: flex;
    gap: 8px;
    align-items: center;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.user-name {
    font-size: 16px;
    font-weight: 800;
}

.user-points {
    font-size: 14px;
    font-weight: 600;
    color: orangered;
}

.user-balance {
    font-size: 14px;
    font-weight: 800;
    color: gold;
}

.user-info .logout-button,
.user-info .login-button,
.user-info .register-button {
    font-size: 16px;
    padding: 8px 16px;
}
